import * as Yup from 'yup';

const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

export const CreateTeacherSchema = Yup.object().shape({
  first_name: Yup.string().required('Campo obligatorio'),
  last_name: Yup.string().required('Campo obligatorio'),
  country: Yup.string().required('Campo obligatorio'),
  city: Yup.string().required('Campo obligatorio'),
  phone_number: Yup.string().required('Campo obligatorio'),
  doc_type: Yup.string().required('Campo obligatorio'),
  status: Yup.string()
    .typeError('Campo obligatorio')
    .required('Campo obligatorio'),
  doc_number: Yup.string().required('Campo obligatorio'),
  password: Yup.string().required('Campo obligatorio'),
  email: Yup.string()
    .matches(emailRegex, 'Formato de correo electrónico no válido')
    .required('Campo obligatorio'),
  optional_email: Yup.string().matches(
    emailRegex,
    'Formato de correo electrónico no válido'
  ),
  modality_CTC: Yup.string().required('Campo obligatorio'),
  date_start: Yup.string()
    .typeError('Campo obligatorio')
    .required('Campo obligatorio'),
  end_date_first_payment_period: Yup.string()
    .typeError('Campo obligatorio')
    .required('Campo obligatorio'),
  payment_frequency: Yup.string()
    .typeError('Campo obligatorio')
    .required('Campo obligatorio'),
  payment_currency: Yup.string()
    .typeError('Campo obligatorio')
    .required('Campo obligatorio'),
  payment_country: Yup.string()
    .typeError('Campo obligatorio')
    .required('Campo obligatorio')
});

import React from 'react';

// Components
import ButtonLineal from '../ButtonLineal';
import SelectOptions from '../SelectOptions';
import ItemFloatingMenu from '../ItemFloatingMenu';

// Redux
import { AppDispatch, useAppDispatch } from '../../../store/store';
import { setTeacherToRoom } from '../../../slices/roomSlice/roomDetailByGroupSlice';

interface Props {
  dataSort: Array<any>;
  identifierSort: string;
  onClickHandler: Function;
  onClickDropdown?: Function;
  teacherInfo: any;
}

const DropdownListTeacher: React.FC<Props> = ({
  dataSort,
  identifierSort,
  onClickHandler,
  teacherInfo,
  onClickDropdown
}) => {
  const dispatch: AppDispatch = useAppDispatch();

  return (
    <SelectOptions
      className="default-dropdown"
      isScroll={dataSort?.length > 6 ? true : false}
      size="default"
      options={dataSort
        ?.sort((a: any, b: any) =>
          a?.[identifierSort] > b?.[identifierSort] ? 1 : -1
        )
        .map((option: any) => (
          <ItemFloatingMenu key={option?.id} dividerItems>
            <div className="default-dropdown__content-item">
              <div
                className="default-dropdown__identifier-item"
                onClick={() => {
                  onClickDropdown && onClickDropdown(option?.id);
                  const teacherDetail = {
                    email: option?.email,
                    full_name: option?.full_name,
                    id: option?.id
                  };
                  onClickHandler(teacherDetail);
                  dispatch(setTeacherToRoom(teacherDetail));
                }}
              >
                {option?.[identifierSort].toLowerCase()}
              </div>
              {onClickDropdown && (
                <ButtonLineal
                  size="small"
                  onClick={() => {
                    onClickDropdown(option?.id);
                  }}
                >
                  Ver más
                </ButtonLineal>
              )}
            </div>
          </ItemFloatingMenu>
        ))}
    />
  );
};

export default DropdownListTeacher;

/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Alert, AlertTitle } from '@material-ui/core';
import {
 Typography, colors, Tab, TabButton 
} from 'ctc-component-library';

import './roomDetail.scss';
import {
  RootState,
  AppDispatch,
  useAppDispatch
} from '../../../../store/store';
import { Students } from './Students/Students';
import { Teachers } from './Teachers/Teachers';
import {
  TEACHER_CTC,
  TEACHER_AUXILIARY
} from '../../../../constants/usersType.constants';
import Evaluations from './Evaluations/Evaluations';
import CommentsSection from './Comments/CommentsSection';
import RoomDetailHeader from './Header/RoomDetailHeader';
import useRoomDetail from '../../../../hooks/useRoomDetail';
import Link from '../../../../components/molecules/Link/Link';
import IconArrow from '../../../../components/atoms//icons/IconArrow';
import NoAccess from '../../../../components/organism/NoAccess/NoAccess';
import useRoomHeaderDetails from '../../../../hooks/useRoomHeaderDetails';
import ScreenLoader from '../../../../components/ScreenLoader/ScreenLoader';
import { setRoom } from '../../../../slices/roomDetailSlice/roomDetailSlice';
import { START_DATE_EVALUATIONS } from '../../../../constants/evaluationsDate.constants';

interface ITabsContent {
  label: string[];
  content: any;
}

function RoomDetailV2() {
  const { roomId } = useParams();
  const dispatch: AppDispatch = useAppDispatch();
  const user = useSelector((state: RootState) => state.auth.user);
  const [activeTabTeachers, setActiveTabTeachers] = useState<number>(0);
  const [activeTabStudents, setActiveTabStudents] = useState<number>(0);
  const [activeTabComments, setActiveTabComments] = useState<number>(0);
  const [tabsContentStudents, setTabsContentStudents] = useState<ITabsContent>({
    label: [],
    content: []
  });
  const [tabsContentTeachers, setTabsContentTeachers] = useState<ITabsContent>({
    label: [],
    content: []
  });
  const [tabsContentComments, setTabsContentComments] = useState<ITabsContent>({
    label: [],
    content: []
  });
  const [shouldRefetch, setShouldRefetch] = useState<boolean>(false);
  const [dateEvaluations, setDateEvaluations] = useState({});

  const refetchDetailRoom = () => {
    setShouldRefetch(!shouldRefetch);
  };

  const {
    data: dataRoomHeader,
    isLoading: isLoadingHeader,
    isError: isErrorHeader,
    error: errorHeader
  } = useRoomHeaderDetails({
    roomId: Number(roomId),
    shouldRefetch: shouldRefetch
  });

  const {
    data: dataRoom,
    isLoading,
    isError,
    error
  } = useRoomDetail({
    roomId: Number(roomId),
    shouldRefetch: shouldRefetch
  });

  useEffect(() => {
    if (dataRoom) {
      dispatch(setRoom(dataRoom));
      if (START_DATE_EVALUATIONS !== '') {
        setDateEvaluations(
          moment(dataRoom?.date_session[0]?.start_date).format('YYYY-MM-DD') >=
            moment(START_DATE_EVALUATIONS).format('YYYY-MM-DD')
        );
      }
      if (dataRoom?.business_type !== 'B2B') {
        setTabsContentStudents({
          label: ['Asistencias', 'Evaluaciones'],
          content: [
            <Students
              key="tab-content-students"
              refetch={refetchDetailRoom}
              room={dataRoom}
            />,
            <Evaluations
              key="tab-content-evaluations"
              refetch={refetchDetailRoom}
              room={dataRoom}
            />
          ]
        });
        setTabsContentTeachers({
          label: ['Asistencias Crack the Code'],
          content: [
            <Teachers
              refetch={refetchDetailRoom}
              room={dataRoom}
              type="teacherCTC"
            />
          ]
        });
        setTabsContentComments({
          label: ['Notas'],
          content: [
            <CommentsSection
              refetchRoomDetails={refetchDetailRoom}
              roomInfo={dataRoom}
            />
          ]
        });
      } else {
        setTabsContentStudents({
          label: ['Asistencias'],
          content: [
            <Students
              key="tab-content-students"
              refetch={refetchDetailRoom}
              room={dataRoom}
            />
          ]
        });
        if (user?.permission?.name !== TEACHER_AUXILIARY) {
          if (
            user?.permission?.name === TEACHER_CTC &&
            dataRoom.auxiliar_teacher.length > 0
          ) {
            setActiveTabTeachers(1);
          }
          setTabsContentTeachers({
            label: ['Asistencias Crack the Code', 'Asistencias externos'],
            content: [
              <Teachers
                refetch={refetchDetailRoom}
                room={dataRoom}
                type="teacherCTC"
              />,
              <Teachers
                refetch={refetchDetailRoom}
                room={dataRoom}
                type="AuxiliaryTeacher"
              />
            ]
          });
        } else {
          setTabsContentTeachers({
            label: ['Asistencias externos'],
            content: [
              <Teachers
                refetch={refetchDetailRoom}
                room={dataRoom}
                type="AuxiliaryTeacher"
              />
            ]
          });
        }
        setTabsContentComments({
          label: ['Apuntes'],
          content: [
            <CommentsSection
              refetchRoomDetails={refetchDetailRoom}
              roomInfo={dataRoom}
            />
          ]
        });
      }
    }
  }, [dataRoom]);

  if (isLoading) return <ScreenLoader />;
  if (
    isError &&
    error?.code === 403 &&
    (user?.permission?.name === TEACHER_CTC ||
      user?.permission?.name === TEACHER_AUXILIARY)
  ) {
    return (
      <NoAccess
        button={{
          to: '/dashboard/rooms',
          message: 'Volver a salones'
        }}
        errorMessage="No tienes acceso a esta sección"
      />
    );
  }
  if (isError || !dataRoom) {
    return (
      <>
        <Link
          className="room-detail-header-v2__button-back"
          iconLeft={<IconArrow className="room-detail-header-v2__icon-back" />}
          to="/dashboard/rooms"
        >
          Regresar a Salones
        </Link>

        <Alert
          severity="error"
          variant="outlined"
          action={
            <Button color="inherit" size="small" onClick={refetchDetailRoom}>
              Reintentar
            </Button>
          }
        >
          <AlertTitle>Error</AlertTitle>
          No se pudo obtener la información del salón. Por favor reintente.
        </Alert>
      </>
    );
  }

  const onChangeTabButton = (tabIndex: number) => {
    if (
      user?.permission?.name === TEACHER_CTC &&
      dataRoom.auxiliar_teacher.length > 0
    ) {
      setActiveTabTeachers(1);
    } else {
      setActiveTabTeachers(0);
    }
    setActiveTabStudents(0);
  };

  return (
    <div style={{ position: 'relative' }}>
      <div className="room-detail-v2__fixed-header">
        <Link
          className="room-detail-v2__button-back"
          children="Regresar a Salones"
          to="/dashboard/rooms"
          iconLeft={<IconArrow className="room-detail-v2__icon-back" />}
        />
        <Typography
          className="room-detail-v2__title"
          variant="h4"
          customStyles={{ fontWeight: '300', color: colors.neutrals.Gray80 }}
        >
          {dataRoomHeader?.name}
        </Typography>
      </div>
      <div className="room-detail-v2__container">
        <RoomDetailHeader
          dataRoomHeader={dataRoomHeader}
          room={dataRoom}
          refetch={refetchDetailRoom}
        />
        <div className="room-detail-v2__body">
          <TabButton
            tabs={[
              {
                label: 'Estudiantes',
                content: (
                  <Tab
                    defaultActiveKey={activeTabStudents}
                    setDefaultActiveKey={setActiveTabStudents}
                    tabs={tabsContentStudents?.label.map((_, index) => ({
                      label: tabsContentStudents?.label[index],
                      content: tabsContentStudents?.content[index]
                    }))}
                  />
                )
              },
              {
                label: 'Profesores',
                content: (
                  <Tab
                    defaultActiveKey={activeTabTeachers}
                    setDefaultActiveKey={setActiveTabTeachers}
                    tabs={tabsContentTeachers?.label.map((_, index) => ({
                      label: tabsContentTeachers?.label[index],
                      content: tabsContentTeachers?.content[index]
                    }))}
                  />
                )
              },
              {
                label: 'Comentarios',
                content: (
                  <Tab
                    defaultActiveKey={activeTabComments}
                    setDefaultActiveKey={setActiveTabComments}
                    tabs={tabsContentComments.label.map((_, index) => ({
                      label: tabsContentComments?.label[index],
                      content: tabsContentComments?.content[index]
                    }))}
                  />
                )
              }
            ]}
            onChange={onChangeTabButton}
          />
        </div>
      </div>
    </div>
  );
}

export default RoomDetailV2;

/* eslint-disable max-lines */
/* eslint-disable complexity */
import { AxiosError } from 'axios';
import { toast } from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import { Tooltip as TooltipMUI } from '@mui/material';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import {
 Typography, colors, Button, Icon 
} from 'ctc-component-library';

import './roomDetailHeader.scss';
import {
  getListOverdueReasons,
  postOverdueContentReport
} from '../../../../../services/b2b/overdueContent';
import { RootState } from '../../../../../store/store';
import { PATCH } from '../../../../../services/api/api';
import { clearString } from '../../../../../utils/utils';
import { useModal } from '../../../../../hooks/useModal';
import SelectComp from '../../../../../components/molecules/SelectComp';
import TypographyBO from '../../../../../components/atoms/Typography/Typography';
import { getHasPendingReport } from '../../../../../services/b2b/overdueContent';
import ModalMessageAlert from '../../../../../components/molecules/ModalMessageAlert';
import { IRoomDetailHeaderSimplified } from '../../../../../interfaces/room.interface';
import {
  ADMIN,
  TEACHER_AUXILIARY
} from '../../../../../constants/usersType.constants';
import ButtonFilled from '../../../../../components/molecules/ButtonFilled/ButtonFilled';
import ButtonLineal from '../../../../../components/molecules/ButtonLineal/ButtonLineal';
import ButtonOutline from '../../../../../components/molecules/ButtonOutline/ButtonOutline';
import { AlertSnackbar } from '../../../../../components/common/AlertSnackbar/AlertSnackbar';
import AuxTeacherActionsModal from '../../../../../components/organism/AuxTeacherActionsModal';
import ModalReportOverdueContent from '../../../../../components/molecules/ModalReportOverdueContent';
import ModalDetailReportOverdueContent from '../../../../../components/molecules/ModalDetailReportOverdueContent';

interface Props {
  dataRoomHeader?: IRoomDetailHeaderSimplified;
  room?: any;
  refetch: () => void;
}

type QtyClases = {
  value: string;
  name: string;
};
type ReasonOverdue = {
  id: number;
  name: string;
};
type RoomDetail = {
  label: string;
  value: string | number | boolean | null | undefined;
  icon?: boolean;
};

const RoomDetailHeader: React.FC<Props> = ({
  dataRoomHeader,
  room,
  refetch
}) => {
  const modalRef = useRef<any>(null);
  const user = useSelector((state: RootState) => state.auth.user);
  const isAdmin = user?.permission.name === ADMIN;
  const teacherAuxiliary = user?.permission?.name === TEACHER_AUXILIARY;
  const [isVisibleMoreInfo, setIsVisibleMoreInfo] = useState(false);

  const [isErrorAlert, setIsErrorAlert] = useState<boolean>(false);
  const [detailReportByRoom, setDetailReportByRoom] = useState();
  const [isPendingReport, setIsPendingReport] = useState<boolean>(false);
  const [qtyClases, setQtyClases] = useState<QtyClases>({
    value: '',
    name: ''
  });
  const [reasonOverdue, setReasonOverdue] = useState<ReasonOverdue>({
    id: 0,
    name: ''
  });
  const [listreasonOverdue, setListReasonOverdue] = useState<any[]>();
  const [externalTeachers, setExternalTeachers] = useState<any>([]);

  const {
    openModal: openModalReportContentDelay,
    closeModal: closeModalReportContentDelay,
    isOpenModal: isOpenModalReportContentDelay
  } = useModal();

  const {
    openModal: openModalAlert,
    closeModal: closeModalAlert,
    isOpenModal: isOpenModalAlert
  } = useModal();

  const {
    openModal: openModalDetailReport,
    closeModal: closeModalDetailReport,
    isOpenModal: isOpenModalDetailReport
  } = useModal();

  const dataClases = [
    { value: '2', name: '2 clases' },
    { value: '3', name: '3 clases' },
    { value: '+4', name: '4 o más clases' }
  ];

  useEffect(() => {
    hasPendingReport();
  }, []);

  const handleZoom = () => {
    if (!room?.online_meeting) {
      toast.error('No existe información de Zoom asociada');
      return;
    } else {
      teacherAuxiliary
        ? window.open(room?.online_meeting?.student_url, '_blank')
        : handleRefreshMeetingLink(); //We need to update Zoom Teacher URL because the Zoom Access Key (ZAK) has a validity of 2 hours since generation. More info here: https://developers.zoom.us/docs/meeting-sdk/auth/
    }
  };

  const handleRecordings = () => {
    if (room?.google_drive_url_share) {
      toast.success('URL de grabaciones copiado...');
      navigator.clipboard.writeText(room?.google_drive_url_share);
    } else toast.error('No existe URL de grabaciones');
  };

  const refreshMeetingLink = useMutation(
    (zoom_meeting_id: string) =>
      PATCH(`/meeting/${zoom_meeting_id}/refresh-start/`, { zoom_meeting_id }),
    { retry: false }
  );

  const handleRefreshMeetingLink = async () => {
    await toast.promise(
      refreshMeetingLink
        .mutateAsync(clearString(room?.online_meeting.external_id))
        .then(({ data: { start_url } }) => {
          setTimeout(() => {
            window.open(start_url, '_blank');
            refetch();
          }, 1000);
        }),
      {
        loading: 'Cargando...',
        success: 'Vamos a Zoom!',
        error: `Ha ocurrido un error. Inicie la clase con la cuenta <${room?.online_meeting.licensed_user_email}>`
      },
      { success: { icon: <InsertLinkIcon color="primary" /> } }
    );
  };

  const handleClickQtyClasses = async (option: any) => {
    setQtyClases(option);
  };

  const handleClickReasonsOverdue = async (option: any) => {
    setReasonOverdue(option);
  };

  const hasPendingReport = async () => {
    try {
      const { data } = await getHasPendingReport(room?.id);
      setDetailReportByRoom(data);
      if (data && data?.status === 'pending') {
        setIsPendingReport(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const listReasons = async () => {
    try {
      const response = await getListOverdueReasons();
      setListReasonOverdue(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSendReportOverdue = async (
    roomId: number,
    count: string,
    reasonId: string | number
  ) => {
    try {
      const response = await postOverdueContentReport({
        roomId,
        count,
        reasonId
      });
      if (response.status === 201) {
        closeModalReport();
        openModalAlert();
        hasPendingReport();
      } else {
        setIsErrorAlert(true);
        setTimeout(() => {
          setIsErrorAlert(false);
        }, 5000);
      }
    } catch (err) {
      const error: AxiosError = err as AxiosError;
      if (error) {
        setIsErrorAlert(true);
        setTimeout(() => {
          setIsErrorAlert(false);
        }, 5000);
      }
    }
  };

  const closeModalReport = () => {
    closeModalReportContentDelay();
    setQtyClases({ value: '', name: '' });
    setReasonOverdue({ id: 0, name: '' });
  };

  const handleClickAddExtTeacher = () => {
    if (modalRef.current) {
      modalRef.current.setInitialValuesExternalTeacher();
    }
  };

  const showMoreDetails = () => {
    setIsVisibleMoreInfo(!isVisibleMoreInfo);
  };

  const firstColumn: RoomDetail[] = [
    {
      label: 'Grupo',
      value: dataRoomHeader?.group.name
    },
    {
      label: 'Horario',
      value: dataRoomHeader?.schedule
        .map(
          (s: any) =>
            `${s.day} ${s.start_time_formatted} a ${s.end_time_formatted}`
        )
        .join('\n')
    },
    {
      label: 'Zoom ID',
      value: dataRoomHeader?.online_meeting.external_id,
      icon: true
    },
    { label: 'Profesor CTC', value: dataRoomHeader?.main_teacher.full_name },
    { label: 'Fecha de inicio', value: dataRoomHeader?.group.start_date },
    { label: 'Fecha de fin', value: dataRoomHeader?.group.end_date }
  ];

  const secondColumn: RoomDetail[] = [
    {
      label: 'Acceso a Moodle',
      value: dataRoomHeader?.course_mdl_id
        ? `https://aprende.crackthecode.la/course/view.php?id=${dataRoomHeader?.course_mdl_id}`
        : '',
      icon: true
    },
    {
      label: 'Licencia',
      value: dataRoomHeader?.online_meeting.licensed_user_email
    },
    {
      label: 'Contraseña licencia',
      value: dataRoomHeader?.online_meeting.licensed_user_password,
      icon: true
    },
    {
      label: 'URL de estudiante',
      value: dataRoomHeader?.online_meeting.student_url,
      icon: true
    },
    {
      label: 'Contraseña meet',
      value: dataRoomHeader?.online_meeting.password,
      icon: true
    }
  ];

  const firstColumnAuxTeacher: RoomDetail[] = [
    {
      label: 'Grupo',
      value: dataRoomHeader?.group.name,
      icon: true
    },
    {
      label: 'Horario',
      value: dataRoomHeader?.schedule
        .map(
          (s: any) =>
            `${s.day} ${s.start_time_formatted} a ${s.end_time_formatted}`
        )
        .join('\n')
    },
    {
      label: 'Profesor CTC',
      value: dataRoomHeader?.main_teacher.full_name,
      icon: true
    },
    {
      label: 'Acceso a Zoom',
      value: dataRoomHeader?.online_meeting.student_url
    }
  ];

  const secondColumnAuxTeacher: RoomDetail[] = [
    {
      label: 'Acceso a Moodle',
      value: dataRoomHeader?.course_mdl_id
        ? `https://aprende.crackthecode.la/course/view.php?id=${dataRoomHeader?.course_mdl_id}`
        : '',
      icon: true
    },
    { label: 'Fecha de inicio', value: dataRoomHeader?.group.start_date },
    { label: 'Fecha de fin', value: dataRoomHeader?.group.end_date }
  ];

  const renderRoomDetails = (roomDetails: RoomDetail[]) => {
    return (
      <React.Fragment>
        {roomDetails.map((detail, index) => (
          <React.Fragment key={index}>
            <Typography
              variant="body5"
              className="room-detail-header-v2__label"
            >
              {detail.label}
            </Typography>
            <span className="room-detail-header-v2__content-value">
              <Typography
                variant="body5"
                className="room-detail-header-v2__value"
              >
                {detail.value}{' '}
              </Typography>
              {detail.icon ? (
                <div
                  className="room-detail-header-v2__container-icon"
                  onClick={() => {
                    if (detail.value) {
                      toast.success(`${detail.label} copiado...`);
                      navigator.clipboard.writeText(`${detail.label}`);
                    } else toast.error('No existe URL de Zoom');
                  }}
                >
                  <Icon iconName="content_copy" />
                </div>
              ) : null}
            </span>
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  };

  return (
    <>
      <div className="room-detail-header-v2__content">
        <div className="room-detail-header-v2__container-title">
          {!isAdmin &&
          !isPendingReport &&
          user?.permission.name !== TEACHER_AUXILIARY ? (
            <Button
              type="tertiary"
              text="⚠️ Reportar atraso de contenido"
              disabled={false}
              onClick={() => {
                openModalReportContentDelay();
                listReasons();
              }}
            />
          ) : null}
          {isPendingReport && (
            <div className="room-detail-header-v2__container-see-report">
              <Typography
                className="room-detail-header-v2__icon-see-report"
                variant="body5"
                customStyles={{ fontWeight: 'bold' }}
              >
                Reporte atraso de contenido
              </Typography>
              <ButtonLineal
                size="small"
                underline={false}
                onClick={() => openModalDetailReport()}
              >
                <TooltipMUI title="Ver reporte" arrow placement="right">
                  <RemoveRedEyeIcon
                    style={{ width: 20, height: 20 }}
                    fill="#000000"
                  />
                </TooltipMUI>
              </ButtonLineal>
            </div>
          )}
        </div>
        <section
          className="room-detail-header-v2__container-details"
          style={{ background: colors.neutrals.Gray20 }}
        >
          <div className="room-detail-header-v2__details">
            <div
              className="room-detail-header-v2__column"
              style={{ height: isVisibleMoreInfo ? 'auto' : '136px' }}
            >
              {renderRoomDetails(
                teacherAuxiliary ? firstColumnAuxTeacher : firstColumn
              )}
            </div>
            <div
              className="room-detail-header-v2__column"
              style={{
                height: teacherAuxiliary
                  ? '200px'
                  : isVisibleMoreInfo
                    ? 'auto'
                    : '136px'
              }}
            >
              {renderRoomDetails(
                teacherAuxiliary ? secondColumnAuxTeacher : secondColumn
              )}
            </div>
          </div>
          <div className="room-detail-header-v2__container-buttons">
            {teacherAuxiliary ? (
              <div />
            ) : (
              <Button
                type="tertiary"
                customBtnClassName="room-detail-header-v2__button-link-records"
                text={
                  isVisibleMoreInfo ? 'Menos información' : 'Más información'
                }
                fontVariant="button2"
                icon={
                  isVisibleMoreInfo
                    ? 'keyboard_arrow_up'
                    : 'keyboard_arrow_down'
                }
                iconType="outlined"
                iconPosition="right"
                customStyles={{ justifyContent: 'flex-start', padding: 0 }}
                onClick={showMoreDetails}
              />
            )}
            <div className="room-detail-header-v2__actions">
              <Button
                type="secondary"
                text="Ir a Zoom"
                fontVariant="button2"
                onClick={() => handleZoom()}
                customStyles={{ width: 160 }}
              />
              <Button
                customBtnClassName="room-detail-header-v2__button-link-records"
                icon="content_copy"
                iconPosition="left"
                type="tertiary"
                fontVariant="button2"
                text="Copiar link a grabaciones"
                onClick={() => handleRecordings()}
                customStyles={{ width: 230 }}
              />
            </div>
          </div>
        </section>
        <AuxTeacherActionsModal
          ref={modalRef}
          roomInfo={room}
          refetchDetailRoom={refetch}
          externalTeachers={externalTeachers}
          setExternalTeachers={setExternalTeachers}
        />
      </div>
      <ModalReportOverdueContent
        width="lg"
        title="Reportar atraso de contenido"
        subtitle={
          <>
            Para que podamos ayudarte, selecciona la cantidad de clases
            atrasadas (redondeando para arriba) y el motivo principal. <br />{' '}
            <b>
              {' '}
              Recuerda que debes notificar sólo si van 2 o más clases con
              atraso.
            </b>
          </>
        }
        body={
          <form>
            <div className="room-detail-header-v2__modal-container">
              <Typography variant="body5" customStyles={{ fontWeight: 'bold' }}>
                Clases atrasadas*
              </Typography>
              <SelectComp
                size="small"
                onclickDefaultValue={(option: any) =>
                  handleClickQtyClasses(option)
                }
                options={dataClases}
                dividerItems
                value={qtyClases}
                placeholder="Seleccionar cantidad de clases atrasadas"
              />

              <Typography variant="body5" customStyles={{ fontWeight: 'bold' }}>
                Motivo principal*
              </Typography>
              <SelectComp
                size="small"
                onclickDefaultValue={(option: any) =>
                  handleClickReasonsOverdue(option)
                }
                options={listreasonOverdue}
                dividerItems
                value={reasonOverdue}
                placeholder="Seleccionar motivo principal"
              />
            </div>
          </form>
        }
        message="Se agrego correctamente el nuevo profesor..."
        isOpenModal={isOpenModalReportContentDelay}
        openModal={openModalReportContentDelay}
        closeModal={closeModalReportContentDelay}
        button1={
          <ButtonOutline size="default" onClick={closeModalReport}>
            Cancelar
          </ButtonOutline>
        }
        button2={
          <ButtonFilled
            size="default"
            disabled={!qtyClases?.value || !reasonOverdue?.id || !room?.id}
            onClick={
              qtyClases?.value && reasonOverdue?.id
                ? () =>
                    handleSendReportOverdue(
                      room?.id,
                      qtyClases?.value,
                      reasonOverdue?.id
                    )
                : () => {}
            }
          >
            Reportar
          </ButtonFilled>
        }
      />
      <ModalDetailReportOverdueContent
        information={detailReportByRoom}
        isAdmin={isAdmin}
        isStatusVisible={true}
        isOpenModal={isOpenModalDetailReport}
        openModal={openModalDetailReport}
        closeModal={closeModalDetailReport}
        button1={
          <ButtonFilled size="default" onClick={() => closeModalDetailReport()}>
            Aceptar
          </ButtonFilled>
        }
      />
      <ModalMessageAlert
        className="table-attendance-student__modal-alert"
        width="sm"
        align="center"
        title={
          <TypographyBO bold type="h1" align="center" variation="mobile">
            ¡Gracias por reportarlo!
          </TypographyBO>
        }
        isOpenModal={isOpenModalAlert}
        openModal={openModalAlert}
        closeModal={closeModalAlert}
        body={
          <TypographyBO align="center" type="paragraph1" variation="mobile">
            Nos pondremos en contacto para encontrar una solución y poder llegar
            a dar todo el contenido.
          </TypographyBO>
        }
        button1={
          <ButtonFilled fullWidth onClick={closeModalAlert}>
            Aceptar
          </ButtonFilled>
        }
      />
      {isErrorAlert ? (
        <AlertSnackbar
          message="Ocurrió un error al enviar la notificación de atraso de contenido. Vuelve a intentar"
          severity="error"
        />
      ) : null}
    </>
  );
};

export default RoomDetailHeader;

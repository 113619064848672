/* eslint-disable max-lines */
/* eslint-disable complexity */
import 'moment/locale/es';
import moment from 'moment';
import { AxiosError } from 'axios';
import { Formik, Form } from 'formik';
import { toast } from 'react-hot-toast';
import { Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';

import './tableGroups.scss';
import FormConfirm from '../FormConfirm';
import colors from '../../atoms/Colors';
import {
  IStudentDetailResale,
  IStudentResale
} from '../../../interfaces/resale.interface';
import IconOn from '../../atoms/icons/IconOn';
import ModalCustomUrl from '../ModalCustomUrl';
import IconOff from '../../atoms/icons/IconOff';
import {
  listGroupById,
  selectStateGroupById
} from '../../../slices/groupSlice/groupByIdSlice';
import { useModal } from '../../../hooks/useModal';
import TagCourses from '../../molecules/TagCourses';
import Modal from '../../../components/Modal/Modal';
import Link from '../../../components/molecules/Link';
import NewModal from '../../../components/atoms/Modal';
import Table from '../../../components/molecules/Table';
import ButtonLineal from '../../molecules/ButtonLineal';
import IconCalendar from '../../atoms/icons/IconCalendar';
import Typography from '../../atoms/Typography/Typography';
import { deleteGroupById } from '../../../services/groups';
import { getResaleDetails } from '../../../services/resale';
import IconUploadFile from '../../atoms/icons/IconUploadFile';
import ModalMessageAlert from '../../molecules/ModalMessageAlert';
import { AppDispatch, useAppDispatch } from '../../../store/store';
import NewModalGroup from '../../../pages/Groups/ModalCreateGroupV2';
import ButtonFilled from '../../../components/molecules/ButtonFilled';
import FloatingMenu from '../../../components/molecules/FloatingMenu';
import ButtonOutline from '../../../components/molecules/ButtonOutline';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import { AlertSnackbar } from '../../common/AlertSnackbar/AlertSnackbar';
import ItemFloatingMenu from '../../../components/molecules/ItemFloatingMenu';
import ModalEditGroup from '../../../pages/Groups/ModalEditGroup/ModalEditGroup';
import { IGroupDetailResponseCreate } from '../../../interfaces/group.interface';
import { addStudents } from '../../../slices/bulkEnrollmentSlice/bulkEnrollmentSlice';
import {
  getAllGroups,
  selectStateGroups
} from '../../../slices/groupSlice/groupsSlice';
import { FILTERS_FIELD_NAMES_TRANSLATIONS } from '../../../constants/groupList.constants';
import DuplicateGroup from '../../../views/Groups/ModalDuplicateGroup/DuplicateGroup/DuplicateGroup';

type FilterState = {
  [key: string]: string;
};

interface Props {
  openModalCreate?: any;
  setOpenModalCreate?: any;
}

const bulkTemplate = process.env.REACT_APP_CTC_BULK_UPLOAD_TEMPLATE;

const TableGroups: React.FC<Props> = ({
  openModalCreate,
  setOpenModalCreate
}) => {
  const size = 100;
  const dispatch: AppDispatch = useAppDispatch();
  const {
    openModal: openModalCustomURL,
    closeModal: closeModalCustomURL,
    isOpenModal: isOpenModalCustomURL
  } = useModal();
  const {
    openModal: openModalConfirmCreateGroup,
    closeModal: closeModalConfirmCreateGroup,
    isOpenModal: isOpenModalConfirmCreateGroup
  } = useModal();
  const {
    openModal: openModalDeleteGroup,
    closeModal: closeModalDeleteGroup,
    isOpenModal: isOpenModalDeleteGroup
  } = useModal();

  const { group: infoGroupById, isLoading: isLoadingGroupById } =
    useSelector(selectStateGroupById);
  const {
 groups, isLoading, count, totalPages 
} =
    useSelector(selectStateGroups);

  const [filters, setFilters] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [programName, setProgramName] = useState<string>();
  const [idGroup, setIdGroup] = useState<number | null>(null);
  const [groupName, setGroupName] = useState<string | null>(null);
  const [openModalDuplicate, setOpenModalDuplicate] = useState(false);
  const [resaleDetails, setResaleDetails] = useState<IStudentDetailResale[]>();
  const [listStudent, setListStudent] = useState<IStudentResale[] | null>(null);
  const [dataGroupCreated, setDataGroupCreated] =
    useState<IGroupDetailResponseCreate>();

  const [isErrorAlert, setIsErrorAlert] = useState<boolean>(false);
  const [isSuccessAlert, setIsSuccessAlert] = useState<boolean>(false);
  const [isLoadingDeleteGroup, setIsLoadingDeleteGroup] =
    useState<boolean>(false);

  const currentFiltersInitialState: FilterState = {};
  const [currentFilters, setCurrentFilters] = useState(
    currentFiltersInitialState
  );
  const [localCurrentFilters, setLocalCurrentFilters] = useState<any>([]);
  const [finalLocalCurrentFilters, setFinalLocalCurrentFilters] = useState<any>(
    []
  );

  const resetFilter = (value: string) => {
    const newStateCurrent = { ...currentFilters };
    delete newStateCurrent[value];
    setCurrentFilters(newStateCurrent);

    const newStateLocal = localCurrentFilters.filter(
      (filter: any) => !filter.hasOwnProperty(value)
    );
    setLocalCurrentFilters(newStateLocal);

    if (newStateLocal.length === 0) {
      setFinalLocalCurrentFilters([]);
    }

    setCurrentPage(1);
  };

  const openBulkTemplate = () => {
    const url = `${bulkTemplate}`;

    window.open(url, '_blank');
  };

  const hasFilters = localCurrentFilters.length > 0;

  useEffect(() => {
    if (!hasFilters) {
      dispatch(getAllGroups({ currentPage, size }));
    }
  }, [currentPage, currentFilters]);

  const transformArray = (inputArray: any): any => {
    const finalArray: any = {};

    inputArray.forEach((item: any) => {
      const key = Object.keys(item)[0];
      const value = item[key];

      switch (key) {
        case 'id':
          finalArray['id'] = value;
          break;
        case 'name':
          finalArray['name'] = value;
          break;
        case 'learning_path':
          finalArray['learning_path'] = value;
          break;
        case 'course':
          finalArray['course'] = value;
          break;
        case 'b2b_project':
          finalArray['b2b_project'] = value;
          break;
        case 'published_at':
          finalArray['published_at'] = value;
          break;
        case 'business_type':
          finalArray['business_type'] = value;
          break;
        default:
          finalArray['id'] = value;
      }
    });

    return finalArray;
  };

  useEffect(() => {
    if (filters) {
      setCurrentPage(1);
      setFilters(false);
      return;
    }

    if (hasFilters) {
      if (currentFilters?.id && currentFilters?.id?.length < 3) {
        return;
      }

      if (currentFilters?.name && currentFilters?.name?.length < 3) {
        return;
      }

      if (
        currentFilters?.learning_path &&
        currentFilters?.learning_path?.length < 3
      ) {
        return;
      }

      if (currentFilters?.course && currentFilters?.course?.length < 3) {
        return;
      }

      if (
        currentFilters?.b2b_project &&
        currentFilters?.b2b_project?.length < 1
      ) {
        return;
      }

      if (
        currentFilters?.published_at &&
        currentFilters?.published_at?.length < 2
      ) {
        return;
      }

      if (
        currentFilters?.business_type &&
        currentFilters?.business_type?.length < 2
      ) {
        return;
      }

      const transformedCurrentFilters = transformArray(localCurrentFilters);
      setFinalLocalCurrentFilters(transformedCurrentFilters);

      dispatch(
        getAllGroups({
          currentPage,
          size,
          id: transformedCurrentFilters?.id,
          name: transformedCurrentFilters?.name,
          program: transformedCurrentFilters?.learning_path,
          course: transformedCurrentFilters?.course,
          project: transformedCurrentFilters?.b2b_project,
          state: transformedCurrentFilters?.published_at,
          business: transformedCurrentFilters?.business_type
        })
      );
    }
  }, [currentPage, filters]);

  useEffect(() => {
    if (!hasFilters) {
      setFilters(false);
    } else {
      setFilters(true);
    }
  }, [currentFilters]);

  const getResale = async (groupId: number) => {
    try {
      const { data } = await getResaleDetails(groupId);
      setResaleDetails(data.students_detail_re_sale);

      if (data.students_detail_re_sale.length < 1) {
        setListStudent([]);
      } else if (
        data.students_detail_re_sale &&
        data.students_detail_re_sale[0].student
      ) {
        const students = data.students_detail_re_sale.map(
          (resale: any) => resale.student
        );
        setListStudent(students);
      }
    } catch (err) {
      const error: AxiosError = err as AxiosError;
      if (error.response?.data?.message) {
        toast.error(error.response?.data?.message); //2202 - 2186
      } else {
        toast.error('Error interno');
      }
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        widthColumn: 60,
        disableSortBy: true
      },
      {
        Header: 'Grupo',
        accessor: 'name',
        widthColumn: 390,
        disableSortBy: true,
        Cell: ({ cell }: any) => (
          <Tooltip title={cell.value} placement="top">
            <span>{cell.value}</span>
          </Tooltip>
        )
      },
      {
        Header: 'Programa',
        accessor: 'learning_path',
        widthColumn: 250,
        disableSortBy: true,
        Cell: ({ cell }: any) => (
          <Tooltip title={cell.value} placement="top">
            <span>{cell.value}</span>
          </Tooltip>
        )
      },
      {
        Header: 'Curso',
        accessor: 'course',
        widthColumn: 250,
        disableSortBy: true,
        Cell: ({ cell }: any) => (
          <Tooltip title={cell.value} placement="top">
            <span>{cell.value}</span>
          </Tooltip>
        )
      },
      {
        Header: 'ID Proyecto B2B',
        accessor: 'b2b_project',
        widthColumn: 150,
        disableSortBy: true,
        align: 'center',
        Cell: ({ cell }: any) => {
          const formattedValue = Array.isArray(cell.value)
            ? cell.value.join(', ')
            : cell.value;
          return (
            <Tooltip title={formattedValue} placement="top">
              <span>{formattedValue}</span>
            </Tooltip>
          );
        }
      },
      {
        Header: 'Estado',
        accessor: 'published_at',
        align: 'center',
        minWidthColumn: 60,
        disableSortBy: true,
        Cell: ({ cell }: any) => (
          <span>{cell.value ? <IconOn /> : <IconOff />}</span>
        )
      },
      {
        Header: 'Negocio',
        align: 'center',
        accessor: 'business_type',
        widthColumn: 60,
        disableSortBy: true,
        Cell: ({ cell }: any) => <span>{cell.value}</span>
      },
      {
        Header: 'Inicio',
        accessor: 'start_date',
        widthColumn: 75,
        align: 'center',
        disableFilters: true,
        Cell: ({ cell }: any) => (
          <>{cell.value ? moment(cell.value).format('DD-MM-YY') : ''}</>
        )
      },
      {
        Header: 'Estudiantes',
        accessor: 'number_of_students',
        widthColumn: 90,
        align: 'center',
        disableFilters: true,
        Cell: ({ cell }: any) => <span>{cell.value}</span>
      },
      {
        Header: '',
        accessor: 'action',
        headerPagination: true,
        actionWidth: 130,
        disableSortBy: true,
        disableFilters: true,
        hasTooltip: true,
        tooltipValue: 'copiar url',
        Cell: (props: any) => (
          <>
            <Link
              className="table-group__link table-group__link--hover-lineal"
              to={`/dashboard/groups/${props.row.values.id}/rooms`}
            >
              Ver grupo
            </Link>
            <FloatingMenu
              items={[
                <ItemFloatingMenu
                  onClick={() => {
                    setOpenModalEdit(true);
                    dispatch(listGroupById(props.row.values.id));
                    setIdGroup(props.row.values.id);
                  }}
                >
                  Editar
                </ItemFloatingMenu>,
                <ItemFloatingMenu
                  onClick={() => {
                    if (props.row.values.id) {
                      toast.success('URL de Enrolment copiado...');
                      navigator.clipboard.writeText(
                        `https://www.crackthecode.la/enrollment-page/${props.row.values.id}?asesor=true`
                      );
                    } else toast.error('No existe URL del Enrolment');
                  }}
                >
                  Copiar URL del curso
                </ItemFloatingMenu>,
                <ItemFloatingMenu
                  onClick={() => {
                    if (props.row.values.id) {
                      toast.success(
                        'URL de Enrolment Learning Path copiado...'
                      );
                      navigator.clipboard.writeText(
                        `https://www.crackthecode.la/enrollment-page/${props.row.values.id}?ruta_completa=true&asesor=true`
                      );
                    } else
                      toast.error('No existe URL del Enrolment Learning Path');
                  }}
                >
                  Copiar URL de Ruta
                </ItemFloatingMenu>,
                <ItemFloatingMenu
                  disabled={
                    !props.row.values.learning_path ||
                    props.row.values.learning_path === 'Cursos Individuales'
                  }
                  onClick={() => {
                    if (
                      props.row.values.learning_path &&
                      props.row.values.learning_path !== 'Cursos Individuales'
                    ) {
                      openModalCustomURL();
                      setListStudent([]);
                      getResale(props.row.values.id);
                      setIdGroup(props.row.values.id);
                      setProgramName(props.row.values.learning_path);
                    }
                  }}
                >
                  Crear URL personalizado
                </ItemFloatingMenu>,
                <ItemFloatingMenu
                  onClick={() => {
                    setOpenModalDuplicate(true);
                    setIdGroup(props.row.values.id);
                  }}
                >
                  Duplicar
                </ItemFloatingMenu>,
                <ItemFloatingMenu
                  color={colors.colorAlertRed}
                  onClick={() => {
                    openModalDeleteGroup();
                    setIdGroup(props.row.values.id);
                    setGroupName(props.row.values.name);
                  }}
                >
                  Eliminar
                </ItemFloatingMenu>
              ]}
            />
          </>
        )
      }
    ],
    []
  );

  const paginationState = {
    pageChangeHandler: setCurrentPage,
    currentPage: currentPage,
    currentFiltersChangeHandler: setCurrentFilters,
    currentFilters: currentFilters,
    setLocalCurrentFilters: setLocalCurrentFilters,
    localCurrentFilters: localCurrentFilters,
    totalRows: count,
    totalPages,
    pageSize: size
  };

  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [hasError, setHasError] = useState(false);
  const [isLoadingFile, setIsLoadingFile] = useState(false);

  const addEllipsis = ({
    str,
    lenght = 5
  }: {
    str: string;
    lenght?: number;
  }) => {
    if (str.length > lenght) {
      return str.substring(0, lenght) + '...';
    }
    return str;
  };

  const FileInput = ({ onChange }: { onChange: any }) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div className="file-input-container">
          <input
            type="file"
            id="file"
            className="file-input"
            onChange={onChange}
          />
          <IconUploadFile />
          <label
            htmlFor="file"
            className="table-group__bulk-upload-file"
            style={{ fontWeight: 700 }}
          >
            Cargar archivo de
            <br />
            inscripción masiva .xlsx
          </label>
        </div>
        {selectedFile && (
          <div className="selected-file">
            {addEllipsis({ str: selectedFile?.name, lenght: 30 })}
          </div>
        )}
      </div>
    );
  };

  const createStudents = async (payload: any) => {
    try {
      const response = await dispatch(addStudents(payload));
      if (response?.payload?.status === 200) {
        setHasError(false);
        toast.success('Carga masiva realizada con exito');
      } else {
        setHasError(true);
        if (response.payload?.status === 500) {
          toast.error('Ha ocurrido un error al cargar los datos');
          return;
        }
        const listRows = response?.payload?.data?.results_fail;
        const listRowsInverse = [...listRows].reverse();
        listRowsInverse.forEach((object: any) => {
          toast.error(object.message);
        });
        if (response.payload?.status === 206) {
          toast.success('Carga masiva realizada parcialmente con exito');
        }
      }
    } catch (error) {
      console.log(error);
      setHasError(true);
    }
  };

  const handleDeleteRoom = async (group_id: number) => {
    setIsLoadingDeleteGroup(true);
    try {
      const response = await deleteGroupById(group_id);
      if (response?.status === 204) {
        setIsSuccessAlert(true);
        setTimeout(() => {
          setIsSuccessAlert(false);
        }, 5000);
      }
      dispatch(getAllGroups({ currentPage, size }));
    } catch (err) {
      const error: AxiosError = err as AxiosError;
      if (error) {
        setIsErrorAlert(true);
        setTimeout(() => {
          setIsErrorAlert(false);
        }, 5000);
      }
    } finally {
      setIsLoadingDeleteGroup(false);
    }
  };

  const deleteRoom = async (group_id: number) => {
    handleDeleteRoom(group_id);
    closeModalDeleteGroup();
  };

  if (isLoading) return <ScreenLoader />;

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Formik
        initialValues={{}}
        onSubmit={async (values: any, { resetForm }) => {
          setIsLoadingFile(true);
          const bodyFormData = new FormData();
          bodyFormData.append('file', values?.file);
          const payload = {
            ...values,
            file: bodyFormData
          };
          await createStudents(payload);

          setSelectedFile(null);
          setIsLoadingFile(false);
          resetForm();
        }}
      >
        {({ setFieldValue }) => (
          <Form className="content-bulk-upload-inputs">
            <div className="content-bulk-upload-inputs">
              <div className="download-bulk-template">
                <ButtonOutline
                  type="reset"
                  size="medium"
                  className="button-donwload-bulk-template"
                  onClick={openBulkTemplate}
                >
                  <IconCalendar />
                  <Typography
                    type="paragraph2"
                    variation="mobile"
                    className=""
                    bold={true}
                  >
                    Acceder al template
                    <br />
                    de carga masiva
                  </Typography>
                </ButtonOutline>
              </div>
              <FileInput
                onChange={(e: any) => {
                  setFieldValue('file', e.target.files[0]);
                  setSelectedFile(e.target.files[0]);
                }}
              />
              <ButtonFilled
                type="submit"
                size="small"
                className="bulk-upload-send-button"
                disabled={!selectedFile || isLoadingFile}
                onClick={() => {}}
              >
                {isLoadingFile ? 'Enviando...' : 'Enviar archivo'}
              </ButtonFilled>
            </div>
          </Form>
        )}
      </Formik>
      {Object.keys(finalLocalCurrentFilters).length > 0 ? (
        <>
          <section className="guardians_filter__container_courses">
            <p className="typography typography__paragraph2 typography--undefined typography__paragraph2--mobile typography__bold  teacher-profile__typography">
              Filtro(s) Actual(es):{' '}
            </p>
            {Object.keys(finalLocalCurrentFilters).map(
              (key: string, index, test) => {
                return (
                  <>
                    <TagCourses className="guardians_filter__tag">
                      <Typography type="paragraph2" variation="mobile" bold>
                        {FILTERS_FIELD_NAMES_TRANSLATIONS.get(key) +
                          ': ' +
                          finalLocalCurrentFilters[key]}
                      </Typography>
                      <ButtonLineal
                        size="small"
                        className="guardians_filter__button_removal"
                        onClick={() => resetFilter(key)}
                      >
                        &#10005;
                      </ButtonLineal>
                    </TagCourses>
                  </>
                );
              }
            )}
          </section>
        </>
      ) : null}
      <Table
        data={groups}
        columns={columns}
        paginationState={paginationState}
      />
      <NewModalGroup
        isOpenModal={openModalCreate}
        openModal={openModalCreate}
        closeModal={() => {
          setOpenModalCreate(false);
        }}
        openModalConfirmCreateGroup={openModalConfirmCreateGroup}
        setDataGroupCreated={setDataGroupCreated}
      />

      {idGroup ? (
        <>
          {infoGroupById &&
          infoGroupById?.id === idGroup &&
          !isLoadingGroupById ? (
            <ModalEditGroup
              type="edit"
              infoGroupById={infoGroupById}
              groupId={idGroup}
              isOpenModal={openModalEdit}
              closeModal={() => setOpenModalEdit(false)}
            />
          ) : null}
          <Modal
            id={idGroup}
            title="Duplicar Grupo"
            fullWidth
            maxWidth="md"
            openModal={openModalDuplicate}
            handleCloseModal={() => setOpenModalDuplicate(false)}
          >
            <DuplicateGroup
              groupId={idGroup}
              onClose={() => setOpenModalDuplicate(false)}
            />
          </Modal>
          {programName && resaleDetails && listStudent && (
            <ModalCustomUrl
              isOpen={isOpenModalCustomURL}
              closeModal={closeModalCustomURL}
              groupId={idGroup}
              program={programName}
              resaleDetails={resaleDetails}
              listStudent={listStudent}
              setListStudent={setListStudent}
            />
          )}
        </>
      ) : (
        ''
      )}
      <NewModal
        width="lg"
        isOpen={isOpenModalConfirmCreateGroup}
        closeModal={closeModalConfirmCreateGroup}
        title=""
        body={
          <div>
            <FormConfirm
              title="¡Grupo creado con éxito!"
              display="grid"
              body={{
                Nombre: dataGroupCreated?.course?.name,
                'Inicio del curso': dataGroupCreated?.start_date,
                Duración: (
                  <span>
                    {dataGroupCreated?.duration_monday ||
                      dataGroupCreated?.duration_tuesday ||
                      dataGroupCreated?.duration_wednesday ||
                      dataGroupCreated?.time_friday ||
                      dataGroupCreated?.time_saturday ||
                      0}{' '}
                    minutos
                  </span>
                ),
                'Días a la semana': (
                  <ul>
                    {dataGroupCreated?.time_monday ? (
                      <li> Lunes {dataGroupCreated?.time_monday} hs</li>
                    ) : null}
                    {dataGroupCreated?.time_tuesday ? (
                      <li> Martes {dataGroupCreated?.time_tuesday} hs</li>
                    ) : null}
                    {dataGroupCreated?.time_wednesday ? (
                      <li> Miércoles {dataGroupCreated?.time_wednesday} hs</li>
                    ) : null}
                    {dataGroupCreated?.time_thursday ? (
                      <li> Jueves {dataGroupCreated?.time_thursday} hs</li>
                    ) : null}
                    {dataGroupCreated?.time_friday ? (
                      <li> Viernes {dataGroupCreated?.time_friday} hs</li>
                    ) : null}
                    {dataGroupCreated?.time_saturday ? (
                      <li> Sábado {dataGroupCreated?.time_saturday} hs</li>
                    ) : null}
                  </ul>
                )
              }}
              closeModal={closeModalConfirmCreateGroup}
            />
          </div>
        }
      />
      <ModalMessageAlert
        className="teacher-profile__modal-alert"
        width="sm"
        align="center"
        title={
          <Typography bold type="h1" variation="mobile" align="center">
            ¿Quieres eliminar el grupo {idGroup}?
          </Typography>
        }
        isOpenModal={isOpenModalDeleteGroup}
        openModal={openModalDeleteGroup}
        closeModal={closeModalDeleteGroup}
        body={
          <Typography
            type="paragraph2"
            variation="mobile"
            color={colors.gris05}
            align="center"
          >
            Al eliminarlo no se podrá volver a recuperar la información del
            grupo {groupName}
          </Typography>
        }
        button1={
          <ButtonFilled
            fullWidth
            disabled={isLoadingDeleteGroup}
            onClick={() => deleteRoom(Number(idGroup))}
          >
            Eliminar
          </ButtonFilled>
        }
        button2={
          <ButtonLineal
            disabled={isLoadingDeleteGroup}
            onClick={closeModalDeleteGroup}
          >
            Cancelar
          </ButtonLineal>
        }
      />
      {isSuccessAlert ? (
        <AlertSnackbar
          message={`Eliminaste el grupo ${idGroup}`}
          severity="success"
        />
      ) : null}
      {isErrorAlert ? (
        <AlertSnackbar
          message={`Ocurrió un error al borrar el grupo ${idGroup}. Vuelve a intentar`}
          severity="error"
        />
      ) : null}
    </div>
  );
};

export default TableGroups;

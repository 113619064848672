import Box from '@mui/material/Box';
import React, { useState } from 'react';

import './comments.scss';
import '../../../rooms.scss';
import Comments from './Comments';
import CommentsBySession from './CommentsBySession';
import { IRoomDetailSimplified } from '../../../../../interfaces/room.interface';
import ButtonFilled from '../../../../../components/molecules/ButtonFilled/ButtonFilled';

interface Props {
  roomInfo: IRoomDetailSimplified;
  refetchRoomDetails: () => void;
}

const CommentsSection: React.FC<Props> = ({ roomInfo, refetchRoomDetails }) => {
  const [indexPanel, setIndexPanel] = useState(0);

  return (
    <>
      <Box
        sx={{
          width: '100%',
          marginTop: '0.5em'
        }}
      >
        <div className="tab-panel-comments">
          <section className="tab-panel-comments__section-buttons">
            <ButtonFilled
              className={`tab-panel-comments__button tab-panel-comments__button--${
                indexPanel === 0 ? 'active' : 'inactive'
              }`}
              onClick={() => setIndexPanel(0)}
              size="small"
            >
              Por sesión
            </ButtonFilled>
            <ButtonFilled
              className={`tab-panel-comments__button tab-panel-comments__button--${
                indexPanel === 1 ? 'active' : 'inactive'
              }`}
              onClick={() => setIndexPanel(1)}
              size="small"
            >
              Por estudiante
            </ButtonFilled>
          </section>
          {indexPanel === 0 && (
            <CommentsBySession room={roomInfo} refetch={refetchRoomDetails} />
          )}
          {indexPanel === 1 && (
            <Comments room={roomInfo} refetch={refetchRoomDetails} />
          )}
        </div>
      </Box>
    </>
  );
};

export default CommentsSection;

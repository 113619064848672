/* eslint-disable complexity */
import moment from 'moment';
import { Formik, Form } from 'formik';
import { useSelector } from 'react-redux';
import { Grid, Divider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Tooltip, IconButton } from '@mui/material';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import './editStudents.scss';
import { useModal } from '../../../hooks/useModal';
import {
  postCreateStudents,
  getStudentsFromAPI
} from '../../../slices/studentSlice/studentSlice';
import {
  getGuardiansFromAPI,
  selectStateGuardians
} from '../../../slices/guardianSlice/guardianSlice';
import {
  updateStudentById,
  selectStateStudentForId
} from '../../../slices/studentSlice/studentByIdSlice';
import { EditStudentSchema } from './validation.schema';
import { useDebounce } from '../../../hooks/useDebounce';
import Link from '../../../components/molecules/Link/Link';
import { StudentCTCInfo } from './StudentForms/StudentCTCInfo';
import TagStatus from '../../../components/atoms/TagStatus/Tag';
import IconArrow from '../../../components/atoms/icons/IconArrow';
import { AppDispatch, useAppDispatch } from '../../../store/store';
import ButtonLineal from '../../../components/molecules/ButtonLineal';
import ButtonFilled from '../../../components/molecules/ButtonFilled';
import { StudentCredentials } from './StudentForms/StudentCredentials';
import Paragraph from '../../../components/common/Paragraph/Paragraph';
import DefaultLayout from '../../../components/templates/DefaultLayout';
import { StudentPersonalInfo } from './StudentForms/StudentPersonalInfo';
import Typography from '../../../components/atoms/Typography/Typography';
import TableStudentRooms from '../../../components/organism/TableStudentRooms';
import { getStudentById } from '../../../slices/studentSlice/studentByIdSlice';
import { AlertSnackbar } from '../../../components/common/AlertSnackbar/AlertSnackbar';
import ModalInactiveStudent from '../../../components/molecules/ModalInactiveStudent/ModalInactiveStudent';

interface Props {
  isDetail?: any;
}

export const EditStudents: React.FC<Props> = ({ isDetail }) => {
  const dispatch: AppDispatch = useAppDispatch();
  const location = useLocation();
  const isCreatePath = location.pathname.includes('create');
  const { student, isLoadingEditStudent } = useSelector(
    selectStateStudentForId
  );
  const { guardians, isLoading: isLoadingGuardians } =
    useSelector(selectStateGuardians);
  const { studentId, guardianId } = useParams();
  const navigate = useNavigate();
  const [isOpenSelectGender, setIsOpenSelectGender] = useState(false);
  const [selectOptionGender, setSelectOptionGender] = useState<any>('');
  const [refetchStudentById, setRefetchStudentById] = useState(false);

  const [isOpenSelectOS, setIsOpenSelectOS] = useState(false);
  const [selectOptionOS, setSelectOptionOS] = useState<any>('');

  const [isOpenSelectTechExperience, setIsOpenSelectTechExperience] =
    useState(false);
  const [selectOptionTechExperience, setSelectOptionTechExperience] =
    useState<any>('');

  const [selectOptionDocumentType, setSelectOptionDocumentType] =
    useState<any>('');
  const [isOpenSelectDocumentType, setIsOpenSelectDocumentType] =
    useState(false);

  const [isSuccessAlert, setIsSuccessAlert] = useState<boolean>(false);
  const [isErrorAlert, setIsErrorAlert] = useState<boolean>(false);

  const [noSyncAlert, setNoSyncAlert] = useState<boolean>(false);

  const {
    openModal: openModalCancelEdit,
    closeModal: closeModalCancelEdit,
    isOpenModal: isOpenModalCancelEdit
  } = useModal();

  type FilterState = {
    [key: string]: string;
  };

  const [currentPage, setCurrentPage] = useState(1);
  const currentFiltersInitialState: FilterState = {};
  const [currentFilters, setCurrentFilters] = useState(
    currentFiltersInitialState
  );

  const getGuardian = (filter?: any) => {
    let payload: any = { currentPage: currentPage };

    if (filter) {
      payload = {
        ...payload,
        filters: filter
      };
    }

    dispatch(getGuardiansFromAPI(payload));
  };

  useEffect(() => {
    setNoSyncAlert(student?.user_mdl_id === 0);

    if (student?.guardian?.id) {
      getGuardian({ id: student.guardian.id });
    }
  }, [student]);

  useEffect(() => {
    if (isCreatePath) {
      getGuardian({ id: guardianId });
    }
  }, [guardianId, isCreatePath]);

  const debouncedCallback = useDebounce((filter: any) => {
    getGuardian(filter);
  }, 500);

  const genderOptions = [
    { id: 'male', name: 'Masculino' },
    { id: 'female', name: 'Femenino' },
    { id: 'unspecified', name: 'No especifica' }
  ];

  const operativeSystemOptions = [
    { order: 1, id: 'noinformation', name: 'Sin información' },
    { order: 2, id: 'none', name: 'Ninguno' },
    { order: 3, id: 'windows', name: 'Windows' },
    { order: 4, id: 'macos', name: 'macOS' },
    { order: 5, id: 'linux', name: 'Linux' },
    { order: 6, id: 'other', name: 'Otro' }
  ];

  const deviceExperienceOptions = [
    { order: 1, id: 'noinformation', name: 'Sin información' },
    { order: 2, id: 'notexperience', name: 'Ninguna' },
    { order: 3, id: 'basic', name: 'Básica' },
    { order: 4, id: 'intermediate', name: 'Intermedia' },
    { order: 5, id: 'advance', name: 'Avanzada' }
  ];

  const documentTypeOptions = [
    { id: 'cedula', name: 'Cédula' },
    { id: 'CPP', name: 'CPP' },
    { id: 'DNI', name: 'DNI' },
    { id: 'passport', name: 'Pasaporte' },
    { id: 'CURP', name: 'CURP' },
    { id: 'alien-certificate', name: 'Carnet de Extranjería' },
    { id: 'NES', name: 'Número establecido por la Secretaría' },
    { id: 'PEP', name: 'Permiso especial de Permanencia' },
    { id: 'PPT', name: 'Permiso de Protección Temporal' },
    { id: 'RC', name: 'Registro Civil de Nacimiento' },
    { id: 'TI', name: 'Tarjeta de Identidad' }
  ];

  const cancelModalCopies = {
    edit: {
      title: 'Tienes cambios sin guardar',
      message: 'Asegúrate de guardarlos para que se reflejen correctamente.',
      saveButton: 'Guardar los cambios',
      cancelButton: 'No guardar los cambios'
    },
    create: {
      title: '¿Deseas cancelar la creación del estudiante?',
      message:
        'Al confirmar, no se guardará el perfil del estudiante y deberás comenzar de nuevo',
      saveButton: 'Volver a crear al estudiante',
      cancelButton: 'Cancelar creación del estudiante'
    }
  };

  const optionSelectionGender = (option: any): void => {
    setSelectOptionGender(option);
  };

  const optionSelectionOS = (option: any): void => {
    setSelectOptionOS(option);
  };

  const optionSelectionTechExperience = (option: any): void => {
    setSelectOptionTechExperience(option);
  };

  const optionSelectionDocumentType = (option: any): void => {
    setSelectOptionDocumentType(option);
  };

  const initialValues = isCreatePath
    ? {
        first_name: '',
        last_name: '',
        birthdate: '',
        school_name: '',
        gender: '',
        device_experience: '',
        operative_system: '',
        email: '',
        password: '',
        password_google: '',
        guardian: Number(guardianId),
        user_mdl_id: '',
        city: '',
        country_student: '',
        doc_number: '',
        doc_type: '',
        personal_email: '',
        phone_number: '',
        created_at_student: ''
      }
    : {
        first_name: student.first_name,
        last_name: student.last_name,
        birthdate: student.birthdate,
        school_name: student.school_name,
        gender: student.gender,
        device_experience: student.device_experience,
        operative_system: student.operative_system,
        email: student.email,
        password: student.password,
        password_google: student.password_google,
        guardian: student?.guardian?.id || Number(guardianId),
        user_mdl_id: student.user_mdl_id,
        city: student.city,
        country_student: student.country_student,
        doc_number: student.doc_number,
        doc_type: student.doc_type,
        personal_email: student.personal_email,
        phone_number: student.phone_number,
        created_at_student: student.created_at_student
      };

  useEffect(() => {
    if (!isCreatePath) {
      dispatch(getStudentById(Number(studentId)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchStudentById]);

  const goToEdit = () => {
    if (isDetail) {
      navigate(`/dashboard/students/${studentId}/edit`);
    }
  };

  const goToProfile = () => {
    closeModalCancelEdit();
    if (isCreatePath) {
      navigate(`/dashboard/students`);
      return;
    }
    setRefetchStudentById(prev => !prev);
    navigate(`/dashboard/students/${studentId}`);
  };

  const funcUpdateStudent = async (student: any) => {
    const res: any = await dispatch(updateStudentById({ studentId, student }));

    if (res?.error?.message) {
      setIsErrorAlert(true);
      setTimeout(() => {
        setIsErrorAlert(false);
      }, 5000);
      return;
    }

    goToProfile();
    setRefetchStudentById(prev => !prev);
    setIsSuccessAlert(true);
    setTimeout(() => {
      setIsSuccessAlert(false);
    }, 5000);

    await dispatch(getStudentsFromAPI({ currentPage: 1, filters: null }));
  };

  const funcCreateStudent = async (student: any) => {
    try {
      const res: any = await dispatch(postCreateStudents(student));
      if (res?.error?.message) {
        setIsErrorAlert(true);
        setTimeout(() => {
          setIsErrorAlert(false);
        }, 5000);
        return;
      }

      goToProfile();
      setIsSuccessAlert(true);
      setTimeout(() => {
        setIsSuccessAlert(false);
      }, 5000);

      await dispatch(getStudentsFromAPI({ currentPage: 1, filters: null }));
    } catch (error) {
      console.log(error);
    } finally {
      await dispatch(getStudentsFromAPI({ currentPage: 1, filters: null }));
    }
  };

  const CustomCellStatusTag = () => (
    <div style={{ display: 'flex' }}>
      <TagStatus
        customStyles={{ marginLeft: '15px', padding: '5px 10px' }}
        className={`tag-status-groups tag-status-groups--has_ended`}
      >
        No sincronizado
      </TagStatus>
      <IconButton style={{ padding: 0, marginLeft: '10px' }}>
        <Tooltip
          title={
            <Typography
              type="paragraph5"
              variation="desktop"
              customStyles={{ color: '#FFFFFF' }}
            >
              {`El estudiante no tiene credenciales, ni 
              Moodle ID. Puedes sincronizarlo desde
              las acciones de la tabla de estudiantes.`}
            </Typography>
          }
          arrow
          placement="top"
          sx={{ fontSize: '100px !important', maxWidth: '240px' }}
        >
          <InfoOutlined
            style={{ width: 20, height: 20, padding: 0 }}
            fill="#000000"
          />
        </Tooltip>
      </IconButton>
    </div>
  );

  return (
    <DefaultLayout contentClassName={'detail-edit-students'}>
      <>
        {isDetail && (
          <>
            <Link
              className="detail-edit-students__button-back"
              children="Regresar a Estudiantes"
              to="/dashboard/students"
              iconLeft={
                <IconArrow className="detail-edit-students__icon-back" />
              }
            />
            <br />
          </>
        )}

        <div
          className={`detail-edit-students__container-header ${
            !isDetail &&
            !isCreatePath &&
            'detail-edit-students__container-tag-student-id-justify-content'
          }`}
        >
          {isDetail && (
            <div
              style={{
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'center'
              }}
            >
              <Typography type="paragraph1" variation="desktop">
                Estudiante:{' '}
                <strong>{`${initialValues.first_name} ${initialValues.last_name}`}</strong>
              </Typography>
              {noSyncAlert && <CustomCellStatusTag />}
            </div>
          )}
          {isCreatePath && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '50px'
              }}
            >
              <Typography bold type="paragraph1" variation="desktop">
                Registrar estudiante
              </Typography>
            </div>
          )}
          {!isCreatePath && (
            <div className="detail-edit-students__container-tag-student-id">
              <div className="detail-edit-students__tag-student-id">
                <Typography
                  type="paragraph5"
                  variation="desktop"
                  customStyles={{ fontWeight: 600 }}
                >
                  ID Backoffice: {studentId}
                </Typography>
              </div>
              <Paragraph
                title="Copiar id de estudiante"
                text={`${studentId}`}
                copyable
                hideText
                direction="right"
              />
            </div>
          )}
        </div>
      </>
      <Grid
        className="detail-edit-students__grid"
        item
        container
        rowGap={1.5}
        xs={12}
      >
        <Formik
          enableReinitialize={true}
          validationSchema={EditStudentSchema}
          initialValues={initialValues}
          onSubmit={async (values, { resetForm }) => {
            const isGuardianObject = typeof values.guardian === 'object';
            const data = {
              first_name: values.first_name,
              last_name: values.last_name,
              birthdate:
                values.birthdate &&
                moment(values.birthdate).format('YYYY-MM-DD'),
              school_name: values.school_name,
              gender: values.gender,
              device_experience: values.device_experience || 'noinformation',
              operative_system: values.operative_system || 'noinformation',
              email: values.email,
              password: values.password,
              password_google: values.password_google,
              guardian: isGuardianObject
                ? values.guardian?.id
                : values.guardian,
              city: values.city,
              country_student: values.country_student,
              country: values.country_student,
              doc_number: values.doc_number,
              doc_type: values.doc_type,
              personal_email: values.personal_email,
              phone_number: values.phone_number,
              created_at_student: values.created_at_student
            };

            if (isCreatePath) {
              await funcCreateStudent(data);
              return;
            }
            await funcUpdateStudent(data!);
          }}
        >
          {({
            dirty,
            isValid,
            errors,
            touched,
            values,
            handleChange,
            setFieldValue,
            submitForm
          }) => {
            const shouldDisable =
              !dirty ||
              errors.first_name ||
              errors.last_name ||
              errors.birthdate ||
              errors.country_student ||
              errors.gender;

            const handleClickCancel = () => {
              if (!isDetail && dirty) {
                openModalCancelEdit();
                return;
              }

              if (isCreatePath && !dirty) {
                navigate(`/dashboard/guardians`);
                return;
              }

              if (!isDetail && !dirty) {
                navigate(`/dashboard/students/${studentId}`);
                return;
              }
            };

            return (
              <Form className="detail-edit-students__form">
                <StudentPersonalInfo
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  isDetail={isDetail}
                  setFieldValue={setFieldValue}
                  isOpenSelectGender={isOpenSelectGender}
                  setIsOpenSelectGender={setIsOpenSelectGender}
                  selectOptionGender={selectOptionGender}
                  setSelectOptionGender={setSelectOptionGender}
                  optionSelectionGender={optionSelectionGender}
                  genderOptions={genderOptions}
                  student={student}
                  guardians={guardians}
                  selectOptionDocumentType={selectOptionDocumentType}
                  setSelectOptionDocumentType={setSelectOptionDocumentType}
                  isOpenSelectDocumentType={isOpenSelectDocumentType}
                  setIsOpenSelectDocumentType={setIsOpenSelectDocumentType}
                  optionSelectionDocumentType={optionSelectionDocumentType}
                  documentTypeOptions={documentTypeOptions}
                  debouncedCallback={debouncedCallback}
                  isCreatePath={isCreatePath}
                />
                <br />
                <br />
                <Divider />
                <StudentCTCInfo
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  isDetail={isDetail}
                  setFieldValue={setFieldValue}
                  isOpenSelectOS={isOpenSelectOS}
                  setIsOpenSelectOS={setIsOpenSelectOS}
                  selectOptionOS={selectOptionOS}
                  setSelectOptionOS={setSelectOptionOS}
                  optionSelectionOS={optionSelectionOS}
                  operativeSystemOptions={operativeSystemOptions}
                  isOpenSelectTechExperience={isOpenSelectTechExperience}
                  setIsOpenSelectTechExperience={setIsOpenSelectTechExperience}
                  selectOptionTechExperience={selectOptionTechExperience}
                  setSelectOptionTechExperience={setSelectOptionTechExperience}
                  deviceExperienceOptions={deviceExperienceOptions}
                  optionSelectionTechExperience={optionSelectionTechExperience}
                  noSyncAlert={noSyncAlert}
                  isCreatePath={isCreatePath}
                />
                <br />
                <br />
                {!isCreatePath && (
                  <>
                    <Divider />
                    <StudentCredentials
                      values={values}
                      errors={errors}
                      touched={touched}
                      handleChange={handleChange}
                      isDetail={isDetail}
                      noSyncAlert={noSyncAlert}
                    />
                  </>
                )}
                {isDetail && !isCreatePath && (
                  <>
                    <br />
                    <br />
                    <Divider />
                    <TableStudentRooms />
                  </>
                )}
                <div style={{ height: '80px' }}></div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'flex-end',
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    backgroundColor: 'white',
                    width: '100%',
                    boxShadow:
                      '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
                    padding: '20px'
                  }}
                >
                  {isDetail ? (
                    <ButtonFilled type="button" size="large" onClick={goToEdit}>
                      Editar
                    </ButtonFilled>
                  ) : (
                    <>
                      <ButtonLineal size="large" onClick={handleClickCancel}>
                        Cancelar
                      </ButtonLineal>
                      <span />
                      <ButtonFilled
                        // @ts-expect-error Error type
                        disabled={shouldDisable}
                        type="button"
                        size="large"
                        onClick={() => {
                          submitForm();
                        }}
                      >
                        Guardar
                      </ButtonFilled>
                    </>
                  )}
                  <div style={{ marginRight: '50px' }} />
                </div>
                <ModalInactiveStudent
                  width="sm"
                  title={
                    cancelModalCopies[isCreatePath ? 'create' : 'edit'].title
                  }
                  message={
                    cancelModalCopies[isCreatePath ? 'create' : 'edit'].message
                  }
                  isOpenModal={isOpenModalCancelEdit}
                  openModal={openModalCancelEdit}
                  closeModal={closeModalCancelEdit}
                  button1={
                    <ButtonFilled
                      fullWidth={true}
                      size="default"
                      onClick={() => {
                        if (!isCreatePath) {
                          submitForm();
                        }
                        closeModalCancelEdit();
                      }}
                    >
                      {
                        cancelModalCopies[isCreatePath ? 'create' : 'edit']
                          .saveButton
                      }
                    </ButtonFilled>
                  }
                  button2={
                    <ButtonLineal size="default" onClick={goToProfile}>
                      {
                        cancelModalCopies[isCreatePath ? 'create' : 'edit']
                          .cancelButton
                      }
                    </ButtonLineal>
                  }
                />
                {isSuccessAlert && (
                  <AlertSnackbar severity="success">
                    <Typography type="paragraph2" variation="mobile">
                      Cambios guardados exitosamente
                    </Typography>
                  </AlertSnackbar>
                )}

                {isErrorAlert && (
                  <AlertSnackbar severity="error">
                    <Typography type="paragraph2" variation="mobile">
                      Ocurrió un error al guardar los cambios. Vuelve a intentar
                    </Typography>
                  </AlertSnackbar>
                )}
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </DefaultLayout>
  );
};

import React from 'react';

import './button.scss';

interface Props {
  children: any;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  className?: string;
  size?: any;
  iconLeft?: any;
  iconRight?: any;
  disabled?: any;
  type?: any;
  fullWidth?: boolean | string | number;
  width?: string | number;
  dataTestid?: string;
  customStyles?: React.CSSProperties;
  href?: string;
  target?: string;
}

const Button: React.FC<Props> = ({
  children,
  onClick,
  size = 'default',
  iconLeft,
  iconRight,
  className,
  disabled,
  type = 'button',
  fullWidth = false,
  width,
  customStyles,
  dataTestid = 'button-test-id',
  href,
  target = '_self',
  ...otherProps
}) => {
  const hasIcon = iconLeft || iconRight ? 'icon' : 'no-icon';

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (disabled) {
      e.preventDefault();
    } else if (onClick) {
      onClick(e);
    }
  };

  const buttonContent = (
    <button
      data-testid={dataTestid}
      disabled={disabled}
      className={`button button__${hasIcon} button__${hasIcon}--${size} ${
        className ? className : ''
      }${width || fullWidth ? ' button--justify-center' : ''}`}
      {...otherProps}
      onClick={handleClick}
      type={type}
      style={{
        width:
          typeof width === 'string' || typeof width === 'number'
            ? width
            : fullWidth === true
              ? '100%'
              : 'auto',
        ...customStyles
      }}
    >
      {iconLeft} {children} {iconRight}
    </button>
  );

  if (href) {
    return (
      <a
        className="button__link"
        href={disabled ? undefined : href}
        target={target}
        style={{
          width:
            typeof width === 'string' || typeof width === 'number'
              ? width
              : fullWidth === true
                ? '100%'
                : 'auto'
        }}
      >
        {buttonContent}
      </a>
    );
  }

  return buttonContent;
};

export default Button;
